import * as React from "react";
import { Typography, Theme, ThemeProvider, Box } from "@mui/material";

export interface ISectionTitleProps {
  title: string;
  id?: string;
  centre?: boolean;
  gutterBottom?: boolean;
  hideTag?: boolean;
}

export function SectionTitle(props: ISectionTitleProps) {
  return (
    <Typography
      variant="h4"
      component="div"
      id={props.id}
      sx={{
        marginBottom: (theme: Theme) =>
          theme.spacing(props.gutterBottom ? 3 : 0),
        fontWeight: "bold",
      }}
      display="flex"
      alignItems="center"
      justifyContent={props.centre ? "center" : ""}
    >
      {!props.hideTag && (
        <Box
          component="span"
          sx={{
            color: (theme: Theme) => theme.palette.primary.main,
            fontSize: "40px",
            marginRight: (theme: Theme) => theme.spacing(2),
          }}
        >
          #
        </Box>
      )}
      <Box component="span">{props.title}</Box>
    </Typography>
  );
}
