import configuration from "./configuration.json";

const exports = {
  sanity: {
    projectId: configuration.sanity.source.projectId,
    dataset: configuration.sanity.source.dataset,
  },
};

export default exports;
